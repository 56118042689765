* {
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}
.app {
  display: flex;
  height: 100%;
  width: 100%;

  .content {
    line-height: 1.5;
    flex-grow: 1;

    h2 {
      font-size: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }

  .content-block {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
  }
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px);
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

.dx-button {
  border: none;
  outline: none;
  font-weight: bold;
}
* {
  box-sizing: border-box;
}

.loginform {
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.links {
  color: #337ab7 !important;

  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}

.Cookiesstyle {
  background-color: #333;
}

.numericos :nth-child(2) :nth-child(1) {
  text-align: right;
}

.dx-form-group-with-caption > .dx-form-group-content {
  padding-top: 19px;
  margin-top: 6px;
  border-top: 3px solid #333;
  padding-bottom: 20px;
}

.printDatosVehiculo {
  background-color: #f1f6fa !important;
  .dx-texteditor-input,
  .dx-textarea .dx-texteditor-input {
    font-size: 12px;
    overflow: visible !important;
    white-space: pre-wrap !important;
    vertical-align: top !important;
    word-wrap: break-word !important;
  }
}
